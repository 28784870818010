import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import BlogArticlesListComponents from '@components/layout/BlogArticlesListComponents/BlogArticlesListComponents';

// === Content === //
import { BlogArticlesListPageDTO } from '@DTO/BlogArticlesListPageDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface BlogPageProps {
  pageContext?: TemplatePageContextData;
}

const BlogArticlesList = ({ pageContext }: BlogPageProps) => {
  const blogPage = BlogArticlesListPageDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(blogPage.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={blogPage.metaData}
        breadcrumbs={blogPage.breadcrumbs}
        stickedForm={blogPage.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={blogPage.langPath}
      >
        <BlogArticlesListComponents
          components={blogPage.components}
          slug={`/${pageContext?.slug || ''}`}
        />
      </Layout>
    </>
  );
};

export default BlogArticlesList;
