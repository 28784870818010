import React, { Fragment, useState } from 'react';

// === Components === //
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Input from '@components/common/Input/Input';
import Button from '@components/common/Button/Button';
import Checkbox from '@components/common/Checkbox/Checkbox';
import Loader from '@components/common/Loader/Loader';
import CustomReCaptchaBanner from '@components/common/CustomReCaptchaBanner/CustomReCaptchaBanner';

// === Helpers === //
import cn from 'classnames';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from '@helpers/validation';
import { globalTranslations } from '@helpers/globalTranslations';
import { setDataLayer } from '@helpers/form';
import * as utils from '@utils';

// === Styles === //
import * as styles from '@components/sections/Newsletter/Newsletter.module.scss';

// === Types === //
import { INewsletter } from '@components/sections/Newsletter/Newsletter.d';
import { FormValues } from '@components/common/Form/Form.d';

const Newsletter = ({
  title,
  description,
  button,
  fields,
  formName,
  success,
  language,
  id,
}: INewsletter) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [successBanner, setSuccessBanner] = useState<boolean>(false);

  // reCaptcha v3
  const [reCaptchaError, setReCaptchaError] = useState<boolean>(false);
  const recaptcha_site_key = globalTranslations('fieldRecaptchaSiteKey');
  const recaptcha_error_message = globalTranslations('fieldRecaptchaError');
  const recaptcha_text = (globalTranslations('fieldRecaptchaCopy') as any)?.processed;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(validationSchema(fields)) });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data) => {
    setLoading(true);
    setReCaptchaError(false);

    if (recaptcha_site_key && recaptcha_site_key !== '') {
      const reCaptchaToken = await (executeRecaptcha as any)('productForm');

      if (reCaptchaToken && reCaptchaToken !== '') {
        formFetchData(data, reCaptchaToken);
      } else {
        setReCaptchaError(true);
        setLoading(false);
      }
    } else {
      formFetchData(data, '');
    }
  };

  const formFetchData = (data, gReCaptchaResponse) => {
    const formData = {
      fieldFormName: formName,
      ca__chat: Cookies.get('__ca__chat') || '',
      fields: Object.keys(data).map((fieldName) => ({
        fieldFieldType: fields.find((field) => field.name === fieldName)?.fieldFieldType,
        fieldFieldName: fieldName,
        value: data[fieldName],
      })),
      referer: typeof window !== 'undefined' ? window.location.href : '',
      ...utils.odooData(language),
      formId: id,
      gReCaptchaResponse,
    };

    axios
      .post(`${process.env.API_FORM_URL}`, {
        ...formData,
      })
      .then((response) => {
        if (response.status === 201 && response.data && response?.data?.results?.email === 'OK') {
          setSuccessBanner(true);
          setDataLayer({
            ...formData,
            formType: 'newsletter',
            classes: 'newsletter__form',
            formSubmit: 'submit',
            odoo_lead_id: response?.data?.results?.odoo_com,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div className={cn(styles['newsletter'])}>
      <div className={cn('page-wrapper', styles['newsletterContainer'])}>
        <div className={cn(styles['newsletterHeading'])}>
          <h2
            className={cn(
              'safe-html-inherit-fonts',
              styles['newsletterHeadingTitle'],
              successBanner && styles['newsletterHeadingTitleSuccess']
            )}
          >
            {successBanner ? utils.safeHtml(success.title) : utils.safeHtml(title)}
          </h2>
          <div
            className={cn(
              'safe-html-inherit-fonts',
              styles['newsletterHeadingDescription'],
              successBanner && styles['newsletterHeadingDescriptionSuccess']
            )}
          >
            {successBanner ? utils.safeHtml(success.description) : utils.safeHtml(description)}
          </div>
        </div>
        {!successBanner && (
          <form
            className={cn(styles['newsletterForm'], 'newsletter__form')}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              {fields.map((field, index: number) => (
                <Fragment key={index}>
                  {field.type === 'input' && (
                    <Input
                      name={field.name}
                      label={field.label}
                      placeholder={field.placeholder}
                      register={register(field.name)}
                      errorMessage={errors[field.name]?.message}
                      mandatory={
                        field.validation.find((v) => v.type === 'mandatory') ? true : false
                      }
                    />
                  )}
                  {field.type === 'checkbox' && (
                    <Checkbox
                      name={field.name}
                      label={field.label}
                      register={register(field.name)}
                      errorMessage={errors[field.name]?.message}
                      mandatory={
                        field.validation.find((v) => v.type === 'mandatory') ? true : false
                      }
                    />
                  )}
                </Fragment>
              ))}

              {recaptcha_site_key &&
                recaptcha_site_key !== '' &&
                recaptcha_text &&
                recaptcha_text !== '' && (
                  <CustomReCaptchaBanner text={recaptcha_text} type="dark" />
                )}

              <div id="recaptcha-container"></div>
              {reCaptchaError && <p className="reCaptcha-error">{recaptcha_error_message}</p>}
            </div>
            <Button type="submit" loading={loading}>
              {loading ? <Loader /> : button}
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Newsletter;
